interface SignInData {
  email: string
  password: string
}

export async function signIn(
  userData: SignInData,
  callbackUrl: string | undefined,
) {
  const { $store, $localStorage } = useNuxtApp()
  const { sentrySetUserId } = useSentry()
  const { signIn, data } = useAuth()
  const { email, password } = userData
  const params = callbackUrl ? { callbackUrl } : { redirect: false }

  await signIn({ email, password }, params)
  $store.commit('setUser', data.value)

  const redirectCookie = useCookie('redirect', { maxAge: 300 })
  if (redirectCookie) {
    redirectCookie.value = null
  }

  $localStorage.addItem('sessionStatus', 'authenticated', 1)

  sentrySetUserId()
}

export async function signOut() {
  const { $store, $localStorage, $sentrySetUser } = useNuxtApp()
  const { signOut } = useAuth()
  const route = useRoute()

  await signOut({ redirect: false })

  $store.commit('setUser', false)
  $store.dispatch('account/clearAccountCredentials')
  $store.dispatch('subscriptions/clearCart')
  $store.dispatch('shopCart/clearCart')
  $store.dispatch('payment/clearPaymentSession')
  $store.dispatch('booksProgress/clearBooksProgress')
  $store.dispatch('books/clearBooksData')

  $localStorage.addItem('sessionStatus', 'unauthenticated', 1)
  $sentrySetUser(null)

  if (route.meta?.middleware === 'authentication') {
    await navigateTo('/')
  }
}

export function useAuthLib() {
  return {
    signIn,
    signOut,
  }
}
