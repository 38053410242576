<template>
  <div class="mt-8 mb-16">
    <ShowIcon v-if="iconName === 'eye-show'" fill="#000" />
    <HideIcon v-if="iconName === 'eye-hide'" fill="#000" />
    &hairsp;<a href="#" aria-hidden="true" @click.prevent="togglePasswordField"
      >{{ $label(togglePasswordLabel) }}
    </a>
  </div>
</template>
<script>
import ShowIcon from '~/assets/icons/show.svg'
import HideIcon from '~/assets/icons/hide.svg'

export default {
  components: {
    ShowIcon,
    HideIcon,
  },
  props: {
    onToggle: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      iconName: 'eye-show',
      passwordFieldType: 'password',
      togglePasswordLabel: 'form.showPassword',
    }
  },
  methods: {
    togglePasswordField() {
      if (this.passwordFieldType === 'password') {
        this.passwordFieldType = 'text'
        this.iconName = 'eye-hide'
        this.togglePasswordLabel = 'form.hidePassword'
      } else {
        this.passwordFieldType = 'password'
        this.iconName = 'eye-show'
        this.togglePasswordLabel = 'form.showPassword'
      }

      this.onToggle(this.passwordFieldType)
    },
  },
}
</script>
